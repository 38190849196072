<template>
    <div v-if="medias.length > 0" class="flex justify-center bg-white w-full">
        <div class="w-container sm:max-w-sm md:max-w-md flex justify-center">
            <div class="h-40 w-full flex justify-center items-center" v-swipe="onSwipe">
                <div class="w-fit">
                    <div v-if="medias.length > 5"
                         class="w-10 h-10 sm:w-8 sm:h-8 flex items-center justify-center rounded-full border border-[#DBD6D5] hover:cursor-pointer"
                         @click="move('left')"
                    >
                        <icons-chevron-left-solid class="w-8 text-grey400"></icons-chevron-left-solid>
                    </div>
                </div>

                <div class="overflow-hidden grow flex"
                     :class="medias.length > 5 ? 'overflow-hidden grow flex' : 'grow flex justify-center'"
                >
                    <div v-for="(media, index) in medias"
                         class="min-w-[20%] md:w-40 sm:w-full sm:min-w-full flex justify-center items-center"
                         :id="'presse' + index"
                    >
                        <div class="w-40 md:w-32 sm:w-full flex justify-center">
                            <nuxt-img
                                format="webp"
                                densities="1x"
                                :alt="'Logo ' + media.nom"
                                loading="lazy"
                                quality="80"
                                :src="imageSrc(media.logo.path)">
                            </nuxt-img>
                        </div>
                    </div>
                </div>

                <div class="w-fit">
                    <div v-if="medias.length > 5"
                         class="w-10 h-10 sm:w-8 sm:h-8 flex items-center justify-center rounded-full border border-grey400 hover:cursor-pointer"
                         @click="move('right')">
                        <icons-chevron-right-solid class="w-8 text-grey400"></icons-chevron-right-solid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

const positionSlider = ref(0);
const isMobile = ref(useNuxtApp().$isMobile);

const { data: medias } = await useAsyncData('medias', async () => {
    const { data } = await useNuxtApp().$axios.get('/media/frise');

    return data;
});

async function move(direction) {
    const displayedNumber = isMobile.value ? 1 : 5;

    if (direction === 'right' && positionSlider.value < medias.value.length - displayedNumber) {
        positionSlider.value++;
    } else if (direction === 'right' && positionSlider.value === medias.value.length - displayedNumber) {
        await medias.value.push(medias.value[(medias.value.length - displayedNumber) - 1]);
        positionSlider.value++;
    } else if (direction === 'left' && positionSlider.value > 0) {
        positionSlider.value--;
    }

    const element = document.getElementById('presse' + positionSlider.value);
    if (element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
        });
    }
}

function onSwipe(direction) {
    if (direction === "left" || direction === "right") {
        move(direction);
    }
}

function imageSrc(nom) {
    const encoded = btoa(nom);
    return useNuxtApp().$axios.defaults.baseURL + `/config/image/${encoded}`;
}
</script>

<style scoped lang="scss">

</style>
