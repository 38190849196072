<template>
    <div class="bg-grey200 pt-14">
        <div class="bg-white max-w-container sm:max-w-sm md:max-w-md mx-auto px-6 py-10">
            <div class="font-berlingske text-4.5xl text-center">
                <span class="text-forest">Top 10</span> des villes où investir en LMNP
            </div>

            <div class="grid grid-cols-7 gap-4 h-[18rem] md:h-[54rem] sm:h-[48rem] mt-12 md:grid-cols-2 sm:grid-cols-2">
                <router-link v-for="(city, index) in citys" :key="index"
                             class="col-span-1 rounded-3xl relative cursor-pointer"
                             :class="[
                                 {'row-span-2': city.type === 'big'},
                                 city.type === 'bonus' ? 'bonus md:h-[8rem] sm:h-[7rem] overflow-hidden' : 'card flex flex-col items-center justify-end z-0 md:row-span-1 sm:row-span-1'
                                 ]"
                             :to="{ name: 'investir-a-ville', params: { ville: city.name }}"
                >
                    <nuxt-img :src="'/images/investir-a/'+ city.name.toLowerCase() +'.png'" :alt="'Lmnp ' + city.name"
                              quality="80" format="webp" class="h-full w-full absolute object-cover rounded-3xl"
                              :class="{'z-0': city.type === 'bonus'}"
                    />

                    <template v-if="city.type !== 'bonus'">
                        <div class="text-white text-3xl opacity-60 font-bold"> {{ (index < 9 ? '0' : '') + (index + 1) }} </div>
                        <div class="text-white text-base font-semibold mb-3"> {{ city.name }} </div>
                    </template>

                    <template v-else>
                        <div class="absolute italic top-0 left-0 h-full w-full bg-forest rounded-3xl flex items-center justify-center text-white cover z-20">
                            + la ville bonus
                        </div>

                        <div class="absolute bottom-4 w-full z-10 text-white text-center font-semibold">
                            {{ city.name.toUpperCase() }}
                        </div>
                    </template>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
const citys = [
    {name: "toulouse", type: 'big'}, {name: "bordeaux", type: 'big'}, {name: "lyon", type: 'big'},
    {name: 'nantes', type: 'small'}, {name: 'rennes', type: 'small'}, {name: 'paris', type: 'small'},
    {name: 'marseille', type: 'small'}, {name: 'montpellier', type: 'small'}, {name: 'angers', type: 'small'},
    {name: 'lille', type: 'small'}, {name: 'strasbourg', type: 'bonus'}
];
</script>

<style lang="scss" scoped>
.card {
    div {
        z-index: 3;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40%;
        background: linear-gradient(to top, #004D43, transparent);
        border-radius: 1.5rem;
        pointer-events: none;
        opacity: 50;
        z-index: 2;
        transition: opacity 1.5s ease, opacity .5s ease;
    }

    &:hover::before {
        opacity: 0;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: linear-gradient(to top, #004D43 10%, #004D4370 30%, #004D4330, transparent);
        border-radius: 1.5rem;
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        transition: opacity 1.5s ease, opacity .5s ease;
    }

    &:hover::after {
        opacity: 50;
    }
}

.bonus {
    .cover {
        transform: translateY(0%);
        transition: transform 0.5s ease;
    }

    &:hover {
        .cover {
            transform: translateY(100%);
        }
    }
}

</style>