<template>
    <div class="bg-forest h-[30rem] sm:h-fit sm:py-16 flex justify-center items-center">
        <div class="w-container h-full sm:max-w-sm md:max-w-md grid grid-cols-2 sm:grid-cols-1 gap-5">
            <div class="sm:w-full flex flex-col items-center justify-center text-white text-center">
                <div class="font-work text-base tracking-[0.5rem] sm:tracking-[0.4rem] mb-3">
                    EN CE MOMENT
                </div>

                <div class="relative text-4.5xl sm:text-3xl font-black font-berlingske tracking-[0.02rem] sm:tracking-normal leading-[2.6875rem]">
                    Accédez gratuitement à<br>
                    nos <span class="text-tangerine">services Premium</span>
                    <rounded-arrow v-if="isMobile === false" class="absolute bottom-0 right-0 translate-y-[50%] translate-x-[120%] w-12 -rotate-[88.63deg] -scale-x-[1]"></rounded-arrow>
                </div>

                <etoile-moche v-if="isMobile === false" class="mt-4 mb-8"></etoile-moche>

                <video-iframe
                    v-else
                    title="La meilleure plateforme pour investir dans l'immobilier locatif !"
                    link="https://www.youtube.com/watch?v=BH5_E8B-uJw"
                    width-full
                    class="my-4">
                </video-iframe>

                <c-button
                    class="bg-tangerine text-base text-white flex items-center justify-center rounded w-fit px-5 py-[1em] sm:py-4 sm:mt-2"
                    :to="{name: 'connexion'}"
                >
                    Je crée mon compte
                    <icons-arrow-narrow-right-solid class="text-white w-5 h-5 ml-3"></icons-arrow-narrow-right-solid>
                </c-button>
            </div>

            <div v-if="isMobile === false" class="my-auto">
                <video-iframe
                    title="La meilleure plateforme pour investir dans l'immobilier locatif !"
                    link="https://www.youtube.com/watch?v=BH5_E8B-uJw"
                    width-full>
                </video-iframe>
            </div>
        </div>
    </div>
</template>

<script setup>
import RoundedArrow from "~/components/icons/RoundedArrow.vue";
import {ref} from "vue";
import VideoIframe from "~/pages/components/VideoIframe.vue";
import EtoileMoche from "~/components/icons/EtoileMoche.vue";

const isMobile = ref(useNuxtApp().$isMobile);
</script>

<style scoped lang="scss">

</style>
