<template>
    <div class="bg-grey100">
        <div class="bg-tangerine-light flex items-center justify-center h-fit py-16 sm:py-0">
            <div class="w-container md:max-w-md sm:max-w-sm flex items-center justify-center sm:flex-col sm:gap-8 sm:pt-[1.875rem] sm:pb-[3.25rem]">
                <div class="w-1/2 sm:w-full sm:max-w-sm">
                    <!-- margin-top négative pour l'alignement avec l'image de droite-->
                    <AccueilLottie class="sm:ml-0 -mt-10 sm:mt-0"></AccueilLottie>

                    <c-button
                        class="bg-tangerine text-base text-white flex items-center justify-center rounded w-fit px-5 py-[1em] sm:py-4 sm:mt-2 sm:w-full sm:ml-0 ml-[12%]"
                        :to="{name: 'je-me-lance'}"
                        large
                    >
                        Je me lance avec Maslow
                        <icons-arrow-narrow-right-solid class="text-white w-5 h-5 ml-3"></icons-arrow-narrow-right-solid>
                    </c-button>
                </div>

                <div class="w-1/2 sm:w-full flex justify-center items-center">
                    <AccueilVideo/>
                </div>
            </div>
        </div>

        <AccueilFrisePresse/>

        <div class="bg-grey200 h-[50rem] sm:h-fit flex flex-col items-center pb-[6.5rem] sm:pb-16">
            <div class="w-container md:max-w-md sm:max-w-sm mx-auto flex flex-col items-center mt-8">
                <h1 class="font-berlingske font-medium sm:font-black text-[2.625rem] sm:text-[1.625rem] text-center tracking-normal sm:leading-8">
                    L’investissement locatif 100 % en ligne
                </h1>

                <div class="flex gap-2 items-start">
                    <h2 class="text-grey7 text-3xl sm:text-lg font-medium leading-[3.25rem]">Découvrez le parcours complet</h2>

                    <rounded-arrow class="w-14 sm:w-6 h-fit z-0 mt-5 sm:mt-3 mb-4"></rounded-arrow>
                </div>

                <AccueilParcoursSlider/>
            </div>
        </div>

        <AccueilPremium/>

        <AccueilSelection v-if="selectionMoment?.length > 0" :selectionMoment="selectionMoment"/>

        <SuccessStories v-if='successStories' :success-stories="successStoriesData"/>

        <AccueilVilleLMNP />

        <AccueilRdv/>

        <AccueilPropos/>

        <Valority/>
    </div>
</template>

<script setup>
import AccueilParcoursSlider from "~/pages/components/AccueilParcoursSlider.vue";
import Valority from "~/pages/components/Valority";
import AccueilPropos from "~/pages/components/AccueilPropos";
import SuccessStories from "@/pages/components/SuccessStories";
import AccueilLottie from "~/pages/components/AccueilLottie.vue";
import AccueilVideo from "~/pages/components/AccueilVideo.vue";
import AccueilFrisePresse from "~/pages/components/AccueilFrisePresse.vue";
import RoundedArrow from "~/components/icons/RoundedArrow.vue";
import AccueilPremium from "~/pages/components/AccueilPremium.vue";
import AccueilRdv from "~/pages/components/AccueilRdv.vue";
import AccueilSelection from "~/pages/components/AccueilSelection.vue";
import {useConfigurationsStore} from "~/store/configurations";
import AccueilVilleLMNP from "~/pages/components/AccueilVilleLMNP.vue";

const {$axios} = useNuxtApp();

useHead({
    title: 'Maslow : l\'investissement locatif clé en main',
    meta: [
        {
            type: 'description',
            content: 'Simple et intuitive, découvrez notre plateforme dématérialisée pour investir dans l\'immobilier locatif.'
        }
    ]
});

const {data: successStoriesData} = await useAsyncData('successStoriesData', async () => {
    const {data} = await $axios.get('/success-story/front');
    return Array.isArray(data) ? data : [];
});

const {data: selectionMoment} = await useAsyncData('selectionMoment', async () => {
    let r = await $axios.get('catalogue/mis-en-avant');
    return r.data;
});

const {data: successStories} = await useAsyncData('successStories', async ()=>{
    return (await useConfigurationsStore().getConfigs(['success-stories']))[0].config.value ?? false;
})

</script>

<style lang="scss" scoped>

</style>
