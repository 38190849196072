<template>
    <div class="flex justify-center py-24 sm:py-14 bg-grey200">
        <div class="max-w-container sm:max-w-sm md:max-w-md bg-forest flex gap-16 sm:gap-8 justify-center items-center text-white p-16 sm:px-8 sm:flex-col">
            <div class="w-1/2 sm:w-full flex flex-col justify-center items-start gap-8">
                <div class="font-berlingske text-5xl sm:text-3xl">
                    Prenons rendez-vous !
                </div>

                <div class="text-lg">Vous avez un projet d’investissement mais vous ne<br>savez pas par où commencer ?</div>

                <c-button class="flex gap-2 items-center border border-white !py-2" :to="{name:'nous-contacter-prendre-rendez-vous'}">
                    <icons-calendar-outline class="w-5"/>
                    Je prends rendez-vous
                </c-button>
            </div>

            <div class="w-1/2 sm:w-full flex flex-col justify-center items-start gap-6">
                <div class="font-medium text-2xl">
                    Nos experts vous accompagnent
                </div>

                <div>
                    Les experts Maslow vous accompagnent tout au long du processus de recherche, de réservation, d’achat
                    et de financement du bien.
                </div>

                <nuxt-img
                    src="/images/home/a-votre-service/experts.png" alt="Les experts : Maslow" loading="lazy"
                    height="64" quality="80"
                    class="h-16">
                </nuxt-img>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped lang="scss">

</style>
