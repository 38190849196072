<template>
    <div class="relative group hover:cursor-pointer w-fit sm:w-full" @click="showModal = true">
        <nuxt-img src="/images/home/miniature.jpg" format="webp"
                  quality="80"
                  height="600"
                  alt="Miniature de la vidéo Découvrez Maslow, un parcours 100% complet pour investir 100% en ligne"
                  class="w-[26.5625rem] sm:w-full rounded-4xl aspect-square sm:aspect-auto object-cover" fetchpriority="high">
        </nuxt-img>

        <div class="w-20 sm:w-16 h-20 sm:h-16 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex justify-center bg-white/50 group-hover:bg-white/60 rounded-full">
            <img src="/images/home/play.svg" alt="Bouton play" class="w-10 sm:w-8">
        </div>
    </div>

    <c-modal v-model="showModal" :closable="true" :width="isMobile ? '95vw' : '70vw'">
        <div class="w-full bg-white rounded-xl text-grey7 shadow-ultra-vnr">
            <div style="padding:56.25% 0 0 0;position:relative;">
                <VideoIframe
                    title="Découvrez Maslow, un parcours 100% complet pour investir 100% en ligne"
                    link="https://www.youtube.com/embed/CxEMkaZhsto?autoplay=1"
                    :styles="['position:absolute;top:0;left:0;width:100%;height:100%;border-radius:0.5rem;background-image:url(\'/images/home/first_frame_video.png\');background-size:cover;']"
                    width-full>
                </VideoIframe>
            </div>
        </div>
    </c-modal>
</template>

<script setup>
import {ref} from "vue";
import VideoIframe from "~/pages/components/VideoIframe.vue";

const showModal = ref(false)
const isMobile = ref(useNuxtApp().$isMobile);
</script>

<style scoped lang="scss">

</style>
