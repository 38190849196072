<template>
    <lottie-player
        src="/lottie/home.json"
        autoplay
        loop
        mode="normal"
        playerSize="hide"
    >
    </lottie-player>
</template>

<script setup>
function fixLottieTransform() {
    let elements = document.getElementsByTagName('lottie-player');

    for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('play', (event) => {
            event.target.shadowRoot.querySelector('svg').style.transform = '';
        });

        elements[i].play();
    }
}

onMounted(fixLottieTransform);
</script>

<style scoped lang="scss">

</style>
