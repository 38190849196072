<template>
    <div class="bg-forest relative pt-24 pb-[4.75rem] sm:pt-16 sm:pb-12 sm:px-4">
        <div class="max-w-container md:max-w-md sm:max-w-sm mx-auto">
            <div class="max-w-container mx-auto">
                <div class="flex md:max-w-md flex-col mb-14 sm:max-w-sm">
                    <div class="text-h3 font-berlingske font-black text-white sm:text-[1.875rem] sm:mb-4">
                        Maslow, une filiale du groupe
                    </div>

                    <nuxt-img
                        format="webp"
                        densities="1x"
                        src="/images/home/partenaires/logo_valority.png"
                        class="w-[12.063rem] sm:w-[9.75rem] xl:mt-2 mb-8" loading="lazy"
                        quality="80"
                        alt="Logo Valority">
                    </nuxt-img>

                    <div class="flex gap-[7.5rem] sm:gap-4 text-white sm:flex-col">
                        <div class="w-1/2 sm:w-full text-lg mb-[2.375rem] pr-4">
                            Le groupe Valority propose des solutions d’investissement et des stratégies patrimoniales
                            globales pour aider ses clients à concrétiser leurs projets de vie. Maslow profite ainsi de
                            l’expertise et des compétences multiples de ce groupe reconnu pour les mettre au service des
                            nouveaux investisseurs.
                        </div>

                        <div class="flex flex-col gap-4">
                            <div class="flex">
                                <icons-check-outline class="w-6 h-6 mr-4"></icons-check-outline>

                                <div class="text-lg font-medium">
                                    + de 2 milliards d'actifs immobiliers gérés
                                </div>
                            </div>

                            <div class="flex">
                                <icons-check-outline class="w-6 h-6 mr-4"></icons-check-outline>

                                <div class="text-lg font-medium">
                                    700 collaborateurs à travers toute la France
                                </div>
                            </div>

                            <div class="flex">
                                <icons-check-outline class="w-6 h-6 mr-4"></icons-check-outline>

                                <div class="text-lg font-medium">
                                    10 métiers au service de votre patrimoine
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

<!--                TODO réactiver ça quand lien Maslow-->
<!--                <card-trustpilot></card-trustpilot>-->

                <div class="flex md:max-w-md flex-col gap-8 m-auto max-w-container mt-16 sm:pt-12 sm:max-w-sm sm:mt-4">
                    <div class="text-white sm:col-span-full">
                        <div class="font-berlingske text-h3 mb-[1.188rem] sm:mb-0 sm:text-[1.875rem] sm:leading-[2.125rem]">
                            Des partenaires de confiance
                        </div>
                    </div>
                    <div v-if="isMobile" class="text-white">
                        Le groupe Valority propose cette offre complète de services à ses investisseurs depuis 35 ans avec une expertise reconnue par tous.
                    </div>

                    <div class="w-full bg-forest5 p-[2.313rem] sm:col-span-full sm:px-5">
                        <div class="text-overline font-semibold text-white/[.34] uppercase mb-2">
                            Construction
                        </div>

                        <div class="flex items-center flex-wrap mb-3 gap-6">
                            <nuxt-img
                                format="webp"
                                densities="1x"
                                src="/images/home/partenaires/prom-bouygues.png"
                                class="w-[7.75rem] sm:w-[6.85rem]" loading="lazy"
                                quality="80"
                                alt="Logo Bouygues">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x"
                                src="/images/home/partenaires/prom-cogedim.png"
                                class="w-[3.687rem]" loading="lazy"
                                quality="80"
                                alt="Logo Cogedim">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x"
                                src="/images/home/partenaires/prom-edouard.png"
                                class="w-[6.062rem] sm:mr-4" loading="lazy"
                                quality="80"
                                alt="Logo Edouard Denis">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x"
                                src="/images/home/partenaires/prom-eiffage.png"
                                class="w-[6.062rem] sm:mr-4" loading="lazy"
                                quality="80"
                                alt="Logo Eiffage">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x"
                                src="/images/home/partenaires/prom-kaufman.png"
                                class="w-[10rem] sm:mr-4" loading="lazy"
                                quality="80"
                                alt="Logo Kaufman Broad">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x"
                                src="/images/home/partenaires/prom-marignan.png"
                                class="w-[5.197rem] sm:mr-4" loading="lazy"
                                quality="80"
                                alt="Logo Marignan">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x" loading="lazy"
                                quality="80"
                                src="/images/home/partenaires/prom-ogic.png"
                                class="w-[5.187rem] sm:mr-4"
                                alt="Logo Ogic">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x" loading="lazy"
                                quality="80"
                                src="/images/home/partenaires/prom-vinci.png"
                                class="w-[7.687rem] sm:mr-4"
                                alt="Logo Vinci">
                            </nuxt-img>
                        </div>

                        <div class="text-overline font-semibold text-white/[.34] uppercase mt-[2.063rem]">
                            Crédit
                        </div>

                        <div class="flex items-center flex-wrap gap-6">
                            <nuxt-img
                                format="webp"
                                densities="1x"
                                src="/images/home/partenaires/credit-banquepop.png"
                                class="w-[7.187rem]" loading="lazy"
                                quality="80"
                                alt="Logo Banque Populaire">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x"
                                src="/images/home/partenaires/credit-banquesavoie.png"
                                class="w-[9.25rem]" loading="lazy"
                                quality="80"
                                alt="Logo Banque de Savoie">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x"
                                src="/images/home/partenaires/credit-bnp.png"
                                class="w-[8.312rem]" loading="lazy"
                                quality="80"
                                alt="Logo BNP">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x"
                                src="/images/home/partenaires/credit-caisseepargne.png"
                                class="w-[7.937rem]" loading="lazy"
                                quality="80"
                                alt="Logo Caisse d'Epargne">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x" loading="lazy"
                                quality="80"
                                src="/images/home/partenaires/credit-agricole.png"
                                class="w-[9.687rem]"
                                alt="Logo Crédit Agricole">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x" loading="lazy"
                                quality="80"
                                src="/images/home/partenaires/credit-lcl.png"
                                class="w-[3.75rem]"
                                alt="Logo LCL">
                            </nuxt-img>

                            <nuxt-img
                                format="webp"
                                densities="1x" loading="lazy"
                                quality="80"
                                src="/images/home/partenaires/credit-societeg.png"
                                class="w-[6.5rem]"
                                alt="Logo Société Générale">
                            </nuxt-img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";

const isMobile = ref(useNuxtApp().$isMobile);

</script>

<script>
import CardTrustpilot from "@/pages/components/CardTrustpilot";

export default {
    name: "Valority",
    components: {
        CardTrustpilot,
    }
}
</script>

<style scoped>

</style>
