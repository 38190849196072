<template>
    <div class="bg-grey200 py-20 sm:py-0">
        <div class="max-w-container md:max-w-md sm:max-w-sm mx-auto">
            <h2 class="font-berlingske text-5xl flex items-end mb-[1.563rem] sm:mb-2 sm:items-center sm:text-[1.875rem] sm:leading-[3.5rem] md:justify-center">
                À propos de
                <logos-full class="w-[10.816rem] ml-4 sm:w-[6.313rem] sm:ml-[.625rem] sm:mt-2"></logos-full>
            </h2>

            <div class="grid grid-cols-12 gap-6 md:max-w-md sm:max-w-sm sm:pt-[1.2rem] sm:pb-0 md:flex md:flex-col-reverse md:items-center">
                <div class="col-span-6 sm:col-span-full">
                    <div class="text-lg mb-6 sm:text-base-sm sm:mt-2">
                        Maslow a été pensée pour répondre aux besoins des investisseurs d'aujourd'hui. Pour ce faire,
                        elle propose :
                    </div>

                    <div class="flex mb-[1.063rem]">
                        <div>
                            <icons-check-outline class="w-6 h-6 mr-4"></icons-check-outline>
                        </div>

                        <div class="text-lg pr-1">
                            <div class="text-grey7">
                                Des solutions d'investissement <span class="font-semibold text-black">sécurisées</span>,
                                avec des biens scorés et dont la note attribuée dépend de nombreux indicateurs analysés,
                                croisés et certifiés par nos experts.
                            </div>
                        </div>
                    </div>

                    <div class="flex mb-[1.063rem]">
                        <div>
                            <icons-check-outline class="w-6 h-6 mr-4"></icons-check-outline>
                        </div>

                        <div class="text-lg pr-1">
                            <div class="text-grey7">
                                Des solutions d'investissement <span class="font-semibold text-black">globales</span>
                                avec un parcours simple et complet pour réserver, financer et gérer votre bien, sans
                                jamais avoir à vous déplacer.
                            </div>
                        </div>
                    </div>

                    <div class="flex mb-[1.063rem] sm:mb-0">
                        <div>
                            <icons-check-outline class="w-6 h-6 mr-4"></icons-check-outline>
                        </div>

                        <div class="text-lg pr-1">
                            <div class="text-grey7">
                                Des solutions d'investissement <span class="font-semibold text-black">éclairées</span>
                                avec des experts immobiliers, courtiers et gestionnaires qui se rendent disponibles
                                quand vous en ressentez le besoin.
                            </div>
                        </div>
                    </div>

                    <div class="text-forest mt-10 md:flex md:justify-center">
                        <c-button class="border border-forest hover:bg-grey200 flex items-center font-medium rounded w-fit sm:w-full sm:justify-center px-6 py-3"
                                  :to="{name: 'a-propos'}"
                        >
                            Qui sommes-nous ?
                            <icons-arrow-narrow-right-outline class="w-5 ml-4"/>
                        </c-button>
                    </div>
                </div>

                <div class="col-start-8 col-span-5 sm:col-span-full sm:mb-[2.688rem] sm:mt-4 md:my-5">
                    <nuxt-img
                        format="webp"
                        src="/images/home/a-propos/img_a_propos.jpeg"
                        alt="l'équipe Maslow"
                        quality="80"
                        sizes="800px"
                        class="rounded-4xl h-4/5 object-cover sm:h-full" loading="lazy"
                        densities="1x">
                    </nuxt-img>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
