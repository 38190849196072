<template>
    <div
        class="w-full sm:order-3 sm:mt-0 sm:mb-8 transition-all ease-in-out duration-700 overflow-hidden bg-forest-light">
        <div class="w-full max-w-container sm:max-w-sm mx-auto md:max-w-md">
            <div class="max-w-container md:max-w-md sm:max-w-sm m-auto relative">
                <div class="flex justify-center items-center w-full mt-24 mb-8 sm:mt-12 sm:mb-4">
                    <h4 class="font-bold text-4.5xl sm:text-[1.625rem] leading-8 font-berlingske text-center text-forest -tracking-[0.02em] z-[1] w-2/4 sm:w-2/3">
                        Les appartements du moment
                    </h4>
                </div>

                <div
                    class="mb-4 sm:mx-auto sm:max-w-sm">
                    <c-frise :show-dot="isMobile" id="container-frise-selection_moment" :index-start="0"
                             color-selected="#948D8D"
                             bg-color="#FFFFFF"
                             :index-end="selectionMoment?.length - 1">
                        <template v-slot:default="{currentIndex}">
                            <Bien
                                v-for="(bien, index) in selectionMoment" :key="bien.slug"
                                :bien="bien"
                                :selectionMoment="true"
                                is-home
                                class="w-1/3 sm:min-w-[21.5rem]"
                                :class="{'ml-0.5 sm:ml-0': index !== 0, 'mr-0.5 sm:mr-0': index !== selectionMoment?.length-1}"
                            >
                            </Bien>
                        </template>
                    </c-frise>
                </div>

                <div class="flex justify-center pb-6 sm:hidden">
                    <c-button class="border border-forest text-forest hover:bg-forest-light flex items-center font-work !py-2"
                              :to="{name: 'catalogue'}"
                              large
                    >
                        Voir tout le catalogue
                        <icons-arrow-narrow-right-solid class="w-5 h-5 ml-3"></icons-arrow-narrow-right-solid>
                    </c-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Bien from "~/pages/catalogue/components/Bien.vue";

const props = defineProps({
    selectionMoment: {
        type: Array,
        required: true
    }
})

const isMobile = ref(useNuxtApp().$isMobile);
</script>

<style scoped lang="scss">

</style>
