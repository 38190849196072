<template>
    <div class="w-container sm:max-w-sm md:max-w-md flex flex-col gap-8 sm:gap-5 items-center justify-center">
        <div class="flex items-center gap-4 sm:max-w-sm">
            <div class="w-8 h-8 flex items-center justify-center rounded-full border sm:hidden"
                 :class="activeTab > 0 ? 'hover:cursor-pointer border-black' : 'border-grey5'"
                 @click="prevTab">
                <icons-chevron-left-solid class="w-6" :class="activeTab > 0? '' : 'text-grey5'"></icons-chevron-left-solid>
            </div>

            <div v-for="(tab, index) in tabs"
                 class="h-fit rounded-lg px-4 py-2 sm:px-2 sm:text-sm font-medium hover:cursor-pointer select-none"
                 :class="activeTab === index ? 'text-white bg-' + tab.color : 'bg-white'"
                 @click="activeTab = index">
                {{ tab.text }}
            </div>

            <div class="w-8 h-8 flex items-center justify-center rounded-full border z-10 sm:hidden"
                 :class="activeTab < 2 ? 'hover:cursor-pointer border-black' : 'border-grey5'"
                 @click="nextTab">
                <icons-chevron-right-solid class="w-8" :class="activeTab < 2 ? '' : 'text-grey5'"></icons-chevron-right-solid>
            </div>
        </div>

        <AccueilParcoursCard
            v-show="activeTab === 0"
            img="/images/home/parcours/carte1.png" alt="Carte sur l'acquisition"
        >
            <template v-slot:text>
                <div class="w-10/12">
                    <div class="text-tangerine text-body-14 font-semibold">
                        ÉTAPE 1
                    </div>

                    <div class="text-2xl sm:text-lg font-semibold font-work sm:leading-6 sm:mt-2">
                        Je choisis et <span class="text-tangerine">achète</span> mon appartement <br class="sm:hidden">
                        <span class="text-tangerine">en ligne</span> sur Maslow.
                    </div>

                    <div class="text-lg text-grey7 font-medium mt-8 sm:mt-6 sm:leading-6">
                        Des apparts’ dans lesquels vous pouvez avoir <span class="font-bold">confiance</span>.
                    </div>

                    <ul class="ml-10 mt-4 text-grey7 text-base list-disc leading-relaxed">
                        <li>Des appartements notés par nos experts</li>
                        <li>Des localisations stratégiques</li>
                        <li>Des DPE performants</li>
                    </ul>

                    <nuxt-link
                        class="flex items-center cursor-pointer w-fit border rounded font-medium text-sm mt-10 px-4 py-2 sm:mt-6 border-tangerine text-tangerine"
                        :to="{name: 'catalogue'}"
                    >
                        Tous les appartements
                        <icons-arrow-narrow-right-outline class="w-6 ml-3"></icons-arrow-narrow-right-outline>
                    </nuxt-link>
                </div>
            </template>
        </AccueilParcoursCard>

        <AccueilParcoursCard
            v-show="activeTab === 1"
            img="/images/home/parcours/carte2.png" alt="Carte sur le financement"
        >
            <template v-slot:text>
                <div class="w-10/12">
                    <div class="text-forest text-body-14 font-semibold">
                        ÉTAPE 2
                    </div>

                    <div class="text-2xl sm:text-lg font-semibold font-work sm:leading-6 sm:mt-2">
                        Je trouve mon <span class="text-forest">crédit</span> avec l’aide d’un <br class="sm:hidden">
                        <span class="text-forest">courtier</span> Maslow.
                    </div>

                    <div class="text-lg text-grey7 font-medium mt-8 sm:mt-6 sm:leading-6">
                        Un service de <span class="font-bold">courtage en crédit</span> intégré.
                    </div>

                    <ul class="ml-10 mt-4 text-grey7 text-base list-disc leading-relaxed">
                        <li>Un courtier dédié</li>
                        <li>Une solution adaptée</li>
                        <li>Un large réseau de partenaires</li>
                    </ul>

                    <nuxt-link
                        class="flex items-center cursor-pointer w-fit border rounded font-medium text-sm mt-12 px-4 py-2 sm:mt-6 border-forest text-forest"
                        :to="{name: 'service-cle-en-main-financer'}"
                    >
                        En savoir plus
                        <icons-arrow-narrow-right-outline class="w-6 ml-3"></icons-arrow-narrow-right-outline>
                    </nuxt-link>
                </div>
            </template>
        </AccueilParcoursCard>

        <AccueilParcoursCard
            v-show="activeTab === 2"
            img="/images/home/parcours/carte3.png" alt="Carte sur la gestion"
        >
            <template v-slot:text>
                <div class="w-10/12">
                    <div class="text-blue7 text-body-14 font-semibold">
                        ÉTAPE 3
                    </div>

                    <div class="text-2xl sm:text-lg font-semibold font-work sm:leading-6 sm:mt-2">
                        Maslow gère la <span class="text-blue7">location</span> de mon bien<br class="sm:hidden">
                        et me <span class="text-blue7">reverse</span> mes loyers.
                    </div>

                    <div class="text-lg text-grey7 font-medium mt-8 sm:mt-6 sm:leading-6">
                        La location de votre bien est assurée par nos <span class="font-bold">gestionnaires</span>.
                    </div>

                    <ul class="ml-10 mt-4 text-grey7 text-base list-disc leading-relaxed">
                        <li>Nous trouvons vos locataires</li>
                        <li>Nous gérons tout l'administratif</li>
                        <li>Vous percevez vos loyers</li>
                    </ul>

                    <nuxt-link
                        class="flex items-center cursor-pointer w-fit border rounded font-medium text-sm mt-12 px-4 py-2 sm:mt-6 border-blue7 text-blue7"
                        :to="{name: 'service-cle-en-main-percevoir-mes-loyers'}"
                    >
                        En savoir plus
                        <icons-arrow-narrow-right-outline class="w-6 ml-3"></icons-arrow-narrow-right-outline>
                    </nuxt-link>
                </div>
            </template>
        </AccueilParcoursCard>
    </div>
</template>

<script setup>
import {ref} from "vue";
import AccueilParcoursCard from "~/pages/components/AccueilParcoursCard.vue";

const activeTab = ref(0);

const tabs = ref([
    {text: 'ACQUISITION', color: 'tangerine'},
    {text: 'FINANCEMENT', color: 'forest'},
    {text: 'GESTION', color: 'blue7'}
]);

const isMobile = ref(useNuxtApp().$isMobile);

function prevTab() {
    if (activeTab.value > 0) {
        activeTab.value--;
    }
}

function nextTab() {
    if (activeTab.value < 2) {
        activeTab.value++;
    }
}
</script>

<style scoped lang="scss">

</style>
